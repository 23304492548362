<template>
  <div class="edit">
    <p>基本资料</p>
    <div class="user-info">
      <UserAvatar
        :user="user"
        size="80"
        fontSize="32"
        medalSize="115"
        :isPreview="true"
      ></UserAvatar>
      <el-upload
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        accept=".png,.jpg,.jpeg"
        action=""
      >
        <span>修改头像</span>
      </el-upload>
      <div>
        <el-input
          type="textarea"
          v-model="user.bio"
          placeholder="这个人很懒，什么也没有写。"
          show-word-limit
          maxlength="50"
          class="design-edit"
          :rows="3"
        ></el-input>
        <el-dialog v-model="cropperAvatarVisible" width="600px" center>
          <div class="cropper-content">
            <div class="cropper-area">
              <vueCropper
                ref="cropperRef"
                :img="cropperOption.img"
                :outputSize="cropperOption.size"
                :outputType="cropperOption.outputType"
                :info="cropperOption.info"
                :full="cropperOption.full"
                :autoCropWidth="cropperOption.autoCropWidth"
                :autoCropHeight="cropperOption.autoCropHeight"
                :canMove="cropperOption.canMove"
                :canMoveBox="cropperOption.canMoveBox"
                :original="cropperOption.original"
                :autoCrop="cropperOption.autoCrop"
                :fixed="cropperOption.fixed"
                :fixedNumber="cropperOption.fixedNumber"
                :centerBox="cropperOption.centerBox"
                :infoTrue="cropperOption.infoTrue"
                :fixedBox="cropperOption.fixedBox"
                @realTime="realTime"
              ></vueCropper>
            </div>
            <div class="cropper-preview">
              <p>头像预览</p>
              <div class="preview-wrap" :style="{
                'width': previews.w + 'px',
                'height': previews.h + 'px',
                'zoom': 80/(previews.w || 1)
            }">
                <div :style="previews.div">
                    <img :src="previews.url" :style="previews.img" />
                </div>
              </div>

            </div>
          </div>
          <el-button type="primary" class="confirm-medal" @click="toUpload">上传头像</el-button>
        </el-dialog>

      </div>
      <div class="confirm-edit">
        <el-button size="mini" type="text" class="back" @click="back">返回</el-button>
        <el-button size="mini" type="primary" class="confirm" @click="confirm"
          >确认</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { updateUserInfo, uploadFile } from '@/apis/userCenter.js'
import { getUserInfo } from '@/apis/login.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import { VueCropper } from 'vue-cropper'
import 'vue-cropper/dist/index.css'

const router = useRouter()
const store = useStore()
const state = reactive({
  user: computed(() => store.state.userInfo.userInfos),
  cropperAvatarVisible: false,
  cropperRef: null,
  // 裁剪组件的基础配置option
  cropperOption: {
    img: '', // 裁剪图片的地址
    info: true, // 裁剪框的大小信息
    outputSize: 1, // 裁剪生成图片的质量
    outputType: 'jpeg', // 裁剪生成图片的格式
    canScale: true, // 图片是否允许滚轮缩放
    autoCrop: true, // 是否默认生成截图框
    autoCropWidth: 320, // 默认生成截图框宽度
    autoCropHeight: 320, // 默认生成截图框高度
    fixedBox: true, // 固定截图框大小 不允许改变
    fixed: true, // 是否开启截图框宽高固定比例
    fixedNumber: [1, 1], // 截图框的宽高比例
    full: false, // 是否输出原图比例的截图
    canMoveBox: true, // 截图框能否拖动
    original: false, // 上传图片按照原始比例渲染
    high: true, // 是否按照设备的dpr 输出等比例图片
    centerBox: true, // 截图框是否被限制在图片里面
    infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
  },
  previews: {} // 裁剪之后的结果
})

const { user, cropperRef, cropperAvatarVisible, cropperOption, previews } = toRefs(state)

const getUserInf = () => {
  debugger
  getUserInfo({ user_id: state.user.id }).then((res) => {
    if (res.code === 0) {
      store.commit('userInfo/setUserInfo', res.data)
    }
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}

const confirm = () => {
  // 保存用户信息
  const data = {
    id: state.user.id,
    name: state.user.name,
    avatar: state.user.avatar,
    bio: state.user.bio
  }
  updateUserInfo(data).then((res) => {
    if (res.code === 0) {
      dialogMsg('success', '修改成功')
      // 更新vuex用户头像和签名信息
      const user = store.state.userInfo.userInfos
      user.avatar = state.user.avatar
      user.bio = state.user.bio
      store.commit('userInfo/setUserInfo', user)
      back()
    } else {
      dialogMsg('error', res.msg)
    }
  }).catch((err) => {
    console.log(err)
    // dialogMsg('error', err)
  })
}

const beforeAvatarUpload = (file) => {
  // 格式要求 jpg/png
  const isJPG = /(\.|\/)(jpe?g|png)$/i.test(file.type)
  if (!isJPG) {
    dialogMsg('warning', '允许上传图片的类型为jpg、jpeg和png')
  }
  // 大小要求 10M
  const isLt1M = file.size / 1024 / 1024 < 10
  if (!isLt1M) {
    dialogMsg('warning', '允许上传图片的大小限制为10M')
  }
  if (isJPG && isLt1M) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = e => {
      state.cropperOption.img = e.target.result // base64
      state.cropperAvatarVisible = true
    }
  }

  return false
}

// 头像预览
const realTime = (data) => {
  state.previews = data
}

const toUpload = () => {
  state.cropperRef.getCropBlob(data => {
    const file = new File([data], `avatar${Date.parse(new Date())}.jpg`, {
      type: 'image/jpeg'
    })
    uploadFile(file, 1, () => {}, 6).then((res) => {
      if (res.code === 0) {
        state.user.avatar = res.data.url
      } else {
        res.code === 17 ? dialogMsg('error', '文件名长度不能超过191个字符（不分数字、字母和汉字）') : dialogMsg('error', '上传图片失败')
      }
    }, 6).catch((err) => {
      console.log(err)
    })
    state.cropperAvatarVisible = false
  })
}

const back = () => {
  router.push({ name: 'personalCenter', query: { id: state.user.id } })
}

onMounted(() => {
  getUserInf()
})
</script>

<style lang="less" scoped>
.edit {
  display: flex;
  >p{
    width: 157px;
    height: 66px;
    line-height: 66px;
    margin-right: 8px;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    color: @active-text-color;
    text-align: center;
    background-color: @colorWhite;
  }
  .user-info {
    width: 791px;
    background-color: @colorWhite;
    border: 1px solid #F1F1F1;
    border-radius: 6px;
    padding: 64px 0 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // .user-avatar{
      // display: unset;
    // }
    span{
      margin-left: -14px;
    }
    .confirm-edit{
      .back,.confirm{
        border-radius: 30px;
        font-size: 16px;
        width: 133px;
        height: 34px;
      }
      .back{
        background: #E4E3FC;
        color: #7972F0;
      }
      .confirm{
        background: #7972F0;
        color: #FFFFFF;
      }
    }
  }
  .design-edit{
    width: 321px;
    margin: 24px auto 40px;
    background: #F6F9FA;
    border-radius: 6px;
    :deep(.el-textarea__inner){
      resize: none;
    }
  }
  .el-button{
    padding: 8px 50px;
    border-radius: 30px;
  }
  .el-button--primary{
    background-color: @active-text-color;
    border:none;
  }
  .el-upload span{
    display: inline-block;
    color: @active-text-color;
    font-size: 14px;
    margin-top: 15px;
  }
}

.cropper-content{
  display: flex;
  justify-content: center;
}

.cropper-area{
  width: 400px;
  height: 400px;
}

.cropper-preview{
  padding: 4px 0 4px 20px;
  width: 100px;
  P {
    text-align: center;
  }
}
:deep(.cropper-view-box),
:deep(.cropper-face) {
    border-radius: 50%;
}

.preview-wrap{
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 32px;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 50%;
}
.confirm-medal {
  padding: 0 50px;
  margin-top: 27px;
  transform: translateX(120%);
}
</style>
